import logo from './logo.svg';
import './App.css';
import ThreeDEffect from './ThreeDEffect';
import ServerInfoPanel from './components/ServerInfoPanel';  // Ensure the path is correct


function App() {
  return (
    <div className="App">
      <ThreeDEffect />
      
      <div className="panel-container">
        <div className="tron-control-panel">
          <div className="welcome-section">
            Welcome Aboard
          </div>

            {/* Add the "About Me" section here */}
          <div className="about-me-section">
            
            <p className="about-me-text">
            Welcome to my personal hub! I'm passionate about software development and technology. 
            This page showcases my solo projects and experiments, where I handle everything from coding to server maintenance.
            If you want to view my curriculum and other relevant documents such as published papers you can access the archive below.
            If you want to view my personal projects you can acces my gitlab server in the button below and login with guest/guest credentials for viewing permissions on all projects.
            </p>
          </div>

          <button className="tron-button">
            <a href="https://archive.jsilva.org" className="tron-link">
               Archive Server
            </a>
          </button>


          <button className="tron-button" >
            <a href="https://gitlab.jsilva.org" className="tron-link">
              GitLab Server
            </a>
          </button>

          

          <button className="tron-button">CV (MIRROR)</button>
          <button className="tron-button">
            <a href="https://www.linkedin.com/in/jose-silva-5a8927168/" className="tron-link">
              LINKEDIN
            </a>
          </button>
        </div>
        <div>
           <ServerInfoPanel />
        </div>
        
      </div>

     
       
      
    </div>
  );
}

export default App;
