// src/components/ServerInfoPanel.js

import React, { useState, useEffect } from 'react';
import fetch from 'node-fetch';


function ServerInfoPanel() {

   
    const [serverInfo, setServerInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [storageInfo, setStorageInfo] = useState([]);

    useEffect(() => {
        fetch('https://jsilva.org:5000/api/server-info')
                .then(response => {
                    console.log('Response received:', response);
                    return response.json();
                })
                .then(data => {
                    console.log('Data received:', data);
                    setServerInfo(data);
                    setLoading(false);
                })
                .catch(error => {
                    console.error("Failed to fetch server info:", error);
                    setLoading(false);
                });

                  // Fetch storage info from your API
        fetch('https://jsilva.org:5000/api/storage-info')
            .then((response) => response.text())
            .then((data) => {
            // Parse the string into an array of lines
            const lines = data.trim().split('\n');

            // Extract storage info from each line
            const parsedStorageInfo = lines.slice(1).map((line) => {
                const [filesystem, size, used, avail, use, mountedOn] = line.split(/\s+/);
                return { filesystem, size, used, avail, use, mountedOn };
            });

            setStorageInfo(parsedStorageInfo);
            })
            .catch((error) => {
            console.error('Error fetching storage info:', error);
            });
    }, []);

    if (loading) return <div>Loading...</div>;

    return (
        <div className="tron-control-panel tron-info-panel">
          <div className="info-section">
            <h2>Server Information</h2>
          </div>
          <div className="info-item">
            <span className="info-label">IP:</span> {serverInfo?.ipAddress}
          </div>
          <div className="info-item">
            <span className="info-label">Domain:</span> {serverInfo?.domainName}
          </div>
          <div className="info-item">
            <span className="info-label">Cores:</span> {serverInfo?.cores}
          </div>
          <div className="info-item">
            <span className="info-label">RAM Usage:</span> {serverInfo?.ramUsage}
          </div>
          <div className="info-item">
            <span className="info-label">Total RAM:</span> {serverInfo?.totalRam}
          </div>
          <div className="info-item">
            <span className="info-label">CPU Model:</span> {serverInfo?.cpuModel}
          </div>
        
          <div className="storage-info">
        <h3>Storage Info</h3>
        <ul>
          {storageInfo.map((storage, index) => (
            <li key={index}>
              <strong>Filesystem:</strong> {storage.filesystem}<br />
              <strong>Size:</strong> {storage.size}<br />
              <strong>Use%:</strong> {storage.use}<br />
            </li>
          ))}
        </ul>
      </div>

        </div>
      );
      
}

export default ServerInfoPanel;
