import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';

function ThreeDEffect() {
  const mountRef = useRef(null);

  useEffect(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });

    renderer.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);

    // Load Neptune (or any other) texture
    const texture = new THREE.TextureLoader().load('8k_earth_nightmap.jpg');

    const geometry = new THREE.SphereGeometry(5, 32, 32, 0, Math.PI * 2, 0, Math.PI);
    const material = new THREE.MeshBasicMaterial({ map: texture }); // Apply texture here
    const ellipsoid = new THREE.Mesh(geometry, material);

    scene.add(ellipsoid);

    camera.position.z = 10;

  
     // Add stars
        const starsGeometry = new THREE.BufferGeometry();
        const starsMaterial = new THREE.PointsMaterial({ color: 0xFFFFFF, size: 0.1 });

        const vertices = [];
        for (let i = 0; i < 5000; i++) { // Adjust this value to increase/decrease the number of stars
            const x = (Math.random() - 0.5) * 2000; // Random position in space
            const y = (Math.random() - 0.5) * 2000;
            const z = (Math.random() - 0.5) * 2000;
            vertices.push(x, y, z);
        }

        starsGeometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
        const stars = new THREE.Points(starsGeometry, starsMaterial);
        scene.add(stars);

      // Star speed
      const starSpeed = 0.05;

      const animate = () => {
          requestAnimationFrame(animate);

          ellipsoid.rotation.x += 0.005;
          ellipsoid.rotation.y += 0.005;

          // Move stars towards the camera
          stars.geometry.attributes.position.array.forEach((value, index) => {
              if (index % 3 === 2) { // z coordinate of vertex
                  stars.geometry.attributes.position.array[index] -= starSpeed;

                  // Reset star position when it gets too close to the camera
                  if (stars.geometry.attributes.position.array[index] < -1000) {
                      stars.geometry.attributes.position.array[index] = 1000;
                  }
              }
          });
          stars.geometry.attributes.position.needsUpdate = true;  // Required to update the geometry

          renderer.render(scene, camera);
      };

      animate();


   return () => {
    renderer.dispose();
    // scene.dispose(); // Remove or comment out this line
    material.dispose();
    geometry.dispose();
};
  }, []);

  return <div ref={mountRef} style={{ position: 'fixed', top: 0, left: 0 }} />;
}

export default ThreeDEffect;
